/**
 * Every Layout: The Grid
 * @source https://every-layout.dev/layouts/grid/
 * @module grid-l
 * @description
 * A custom element for creating a responsive grid using the CSS Grid module
 * @property {string} min=250px A CSS length value representing x in `minmax(min(x, 100%), 1fr)`
 * @property {string} space=var(--space) The space between grid cells
 */
export default class Grid extends HTMLElement {
  render: () => void

  constructor() {
    super()
    this.render = () => {
      const variantId = `Grid-${[this.min, this.space].join('')}`
      this.dataset.i = variantId
      if (document.getElementById(variantId)) return

      const styleEl = document.createElement('style')
      styleEl.id = variantId
      styleEl.innerHTML = `
        [data-i="${variantId}"] {
          grid-gap: ${this.space};
        }

        @supports (width: min(${this.min}, 100%)) {
          [data-i="${variantId}"] {
            grid-template-columns: repeat(auto-fill, minmax(min(${this.min}, 100%), 1fr));
          }
        }`
        .replace(/\s\s+/g, ' ')
        .trim()
      document.head.appendChild(styleEl)
    }
  }

  get min() {
    return this.getAttribute('min') || '250px'
  }

  set min(val) {
    this.setAttribute('min', val)
  }

  get space() {
    return this.getAttribute('space') || 'var(--space)'
  }

  set space(val) {
    this.setAttribute('space', val)
  }

  static get observedAttributes() {
    return ['min', 'space']
  }

  connectedCallback() {
    this.render()
  }

  attributeChangedCallback() {
    this.render()
  }
}

if ('customElements' in window && undefined === customElements.get('grid-l')) {
  customElements.define('grid-l', Grid)
}
