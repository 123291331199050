export const scrollToTop = () => window.scroll(0, 0)

export const scrollToRef = (ref: React.MutableRefObject<HTMLElement | null>) => ref.current && scrollToNode(ref.current)

/**
 * @note this works in some cases where scrollIntoView() doesn't ¯\_(ツ)_/¯
 */
export const scrollToNode = (node: HTMLElement) => window.scroll(0, absTopOffset(node))

const absTopOffset = (el: HTMLElement): number => {
  const parent = el.offsetParent as HTMLElement | null
  if (!parent) return 0
  return parent.offsetTop + absTopOffset(parent)
}
