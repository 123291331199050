import Cookie from 'universal-cookie'
import analytics, { BOOTSTRAP_DATA_COOKIE_NAME, PosthogBootstrapDataCookieValue } from '@betterplace/product-analytics'
import { TrackingCookieType } from '../use_tracking_cookie'
import { useEffect } from 'react'
const cookies = new Cookie()
export const useAnalytics = (status: TrackingCookieType | undefined) => {
  useEffect(() => {
    if (status === 'accepted') {
      return analytics.optIn()
    }
    if (status === 'rejected') {
      return analytics.optOut()
    }
    analytics.optInOutPending()
  }, [status])

  useEffect(() => {
    const bootstrapData = cookies.get<PosthogBootstrapDataCookieValue | undefined>(BOOTSTRAP_DATA_COOKIE_NAME)
    const debug = !process.env.HONEYBADGER_ENV?.toLowerCase().includes('production')
    const enable =
      !!process.env.ENABLE_POSTHOG || (process.env.NODE_ENV === 'production' && process.env.RAILS_ENV !== 'test')
    analytics.initialize({
      apiKey: process.env.POSTHOG_API_KEY || '',
      enable,
      debug,
      bootstrapData,
    })
    return () => {
      analytics.cancel()
    }
  }, [])
}
