import Cookies from 'universal-cookie'
import GemeinsamHelfenLogo from '@betterplace/assets/images/logos/gemeinsam_helfen_de.svg'
import NussbaumLogo from '@betterplace/assets/images/logos/nussbaum.svg'
import React from 'react'
import WirWunderLogo from '@betterplace/assets/images/logos/wirwunder_logo.svg'
import WirWunderSparkasse from '@betterplace/assets/images/logos/wirwunder_sparkasse.svg'
import classNames from 'classnames'
import styles from './client_header.module.css'
import { ClientHeaderProps } from './type'

function cookieValueForProject(id: string) {
  const cookies = new Cookies()
  const clientHeaderCookie = cookies.get('client-header')
  if (!clientHeaderCookie) return

  return (clientHeaderCookie as Record<string, string>)[id] as string
}

const WirWunderHeader = ({ wirWunderWhiteLogo }: { wirWunderWhiteLogo?: string }) => {
  return (
    <div className={classNames(styles.projectsWirwunderHeader, styles.container)}>
      <img src={WirWunderLogo} className={styles.logoWirwunder} alt="WirWunder Logo" width={128} />
      <img
        src={wirWunderWhiteLogo ?? WirWunderSparkasse}
        className={styles.logoSparkasse}
        alt="Sparkasse Logo"
        width={122}
        height={70}
      />
    </div>
  )
}

const NussbaumHeader = () => {
  return (
    <div className={classNames(styles.projectsNussbaumHeader, styles.container)}>
      <img src={GemeinsamHelfenLogo} className={styles.logoGemeinsamHelfen} alt="gemeinsamhelfen.de Logo" width={128} />
      <img src={NussbaumLogo} className={styles.logoNussbaum} alt="Nussbaum Logo" width={122} height={70} />
    </div>
  )
}

export const ClientHeader = ({ projectId, wirWunderWhiteLogoUrl }: ClientHeaderProps): JSX.Element => {
  const cookieValue = cookieValueForProject(projectId)
  const hasWirWunderCookie = cookieValue?.startsWith('ww-')
  const hasNussbaumCookie = cookieValue === 'nussbaum'
  if (hasWirWunderCookie) {
    return <WirWunderHeader wirWunderWhiteLogo={wirWunderWhiteLogoUrl} />
  }
  if (hasNussbaumCookie) {
    return <NussbaumHeader />
  }
  return <></>
}
