import Bowser from 'bowser'
export const addBrowserAnnotations = () => {
  const browser = Bowser.getParser(window.navigator.userAgent)
  const name = browser.getBrowserName().toLowerCase().replace(/\s+/g, '-')
  const html = document.querySelector('html')
  const version = parseInt(browser.getBrowserVersion(), 10)
  let legacyName
  if (name === 'internet-explorer') legacyName = 'msie'
  if (name === 'microsoft-edge') legacyName = 'edge'
  if (name === 'firefox') legacyName = 'mozilla'

  html?.classList.add(`browser-version-${version}`)
  html?.classList.add(`browser-${name}`)
  if (legacyName) html?.classList.add(`browser-${legacyName}`)
}
