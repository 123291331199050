import React from 'react'

import classNames from 'classnames'
import { LogoSpinnerProps } from './types'

export const LogoSpinner = ({
  style = {},
  svgClasses,
  height,
  smallSpinner,
  color: stroke = '#ffffff',
}: LogoSpinnerProps): JSX.Element => {
  return (
    <svg
      style={{ ...style, height }}
      className={classNames(svgClasses, 'logo-spinner', { 'small-spinner': smallSpinner })}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 3968.5 2834.6"
      data-testid="logo-spinner"
    >
      <path
        style={{ stroke }}
        d="M2572.6,805.7c-33.3,35.8-199.5,207-217.4,226.3c-73-78.6-188.4-271.1-408.5-273.4
      c-134,0.2-240.6,50.9-322.5,132.1c-111.9,122.6-97.9,205.3-100,262.8c-4.4,118.6,49,187.5,196.6,348.5
      c241.3,261.1,487.4,543.4,558.9,619.9c69.9,74.7,60.5,82.1,130.3,7c66-71,137.1-131.2,238.6-241c344.8-372.9,412.6-763.5,298-1085.7
      c-108.2-304.1-512.5-637.1-967.4-637.3c-467.3,3.3-872.3,317.9-975.9,637.6c-69.6,214.8-95.9,635.9,316.8,1091.8l733.3,788.1"
      />
    </svg>
  )
}
