import React, { useCallback, useMemo, useState } from 'react'
import { Modal } from './types'
import { useOnEscapePressed } from 'shared'

/**
 * @desc hook function providing modal functionality
 */
export const useModal = (): Modal => {
  const [active, setActive] = useState(false)
  const show: (event?: React.BaseSyntheticEvent) => void = useCallback(
    (event) => {
      if (event) event.preventDefault()
      setActive(true)
    },
    [setActive]
  )
  const hide: (event?: React.BaseSyntheticEvent) => void = useCallback(
    (event) => {
      if (event) event.preventDefault()
      setActive(false)
    },
    [setActive]
  )
  const toggle: (event?: React.BaseSyntheticEvent) => void = useCallback(
    (event) => {
      if (event) event.preventDefault()
      setActive(!active)
    },
    [setActive, active]
  )
  useOnEscapePressed(hide)

  const background = useMemo(
    () =>
      active ? (
        <div
          aria-hidden="true"
          className="modal"
          style={{ display: 'block', background: 'black', opacity: 0.1 }}
          onClick={() => setActive(false)}
        />
      ) : null,
    [setActive, active]
  )

  return { active, background, hide, show, toggle }
}
