import Cookies from 'universal-cookie'
import React, { useCallback, useState } from 'react'
import { GtmHelper } from 'shared'
import { InView } from 'react-intersection-observer'
import { ProjectTeaser } from 'betterplace-react-components'
import { ProjectTeaserWithTrackingProps } from './types'
import { components } from '@betterplace/api-v4-types'
const cookies = new Cookies()

export const ProjectTeaserWithTracking = ({
  index,
  project,
  teaserTracking,
  trackingPath,
  ...teaserProps
}: ProjectTeaserWithTrackingProps) => {
  const [tracked, setTracked] = useState(false)

  const handleClick = useCallback(() => {
    GtmHelper.pushProjectClicks(project, index + 1, trackingPath)
    if (teaserTracking) {
      const expires = new Date()
      expires.setTime(expires.getTime() + 3600 * 1000 * 3)
      cookies.set(`teaser-tracker-p${project.id}`, teaserTracking, { path: '/', expires })
    }
  }, [index, project, teaserTracking, trackingPath])

  const handleVisibilityChange = useCallback(
    (isVisible: boolean) => {
      if (!isVisible || tracked) return
      GtmHelper.pushProjectImpressions(project, index + 1, trackingPath)
      setTracked(true)
    },
    [index, project, tracked, trackingPath]
  )

  return (
    <InView as="div" className="w-full relative" onChange={handleVisibilityChange} fallbackInView>
      {Array.isArray(project.matching_events) && project.matching_events.length > 0 && (
        <MatchingEventBanner matchingEvent={project.matching_events[0] as DedicatedMatchingEvent} />
      )}
      <ProjectTeaser project={project} textColor="black" onClick={handleClick} {...teaserProps} />
    </InView>
  )
}

type DedicatedMatchingEvent = components['schemas']['DedicatedMatchingEvent']

const MatchingEventBanner = ({ matchingEvent }: { matchingEvent: DedicatedMatchingEvent }) => {
  const percentage = matchingEvent.matching_percentage
  const localizedPercentageMultiplierDictionary: { [key: string]: string } = {
    de_100: 'verdoppelt',
    de_200: 'verdreifacht',
    de_300: 'vervierfacht',
    en_100: 'doubled',
    en_200: 'tripled',
    en_300: 'quadrupled',
  }
  const multiplierVerb = localizedPercentageMultiplierDictionary[i18n.locale + '_' + percentage]
  const matchingEventBannerWording =
    multiplierVerb === undefined
      ? i18n.t('discover.matching_events.teaser_banner', { percentage: percentage })
      : i18n.t('discover.matching_events.teaser_banner_with_multiplier_wording', { multiplier_verb: multiplierVerb })

  return (
    <div
      className="matching-event-banner"
      style={{
        position: 'absolute',
        inlineSize: '100%',
        fontSize: '0.8425rem',
        fontWeight: 700,
        lineHeight: 1.5,
        backgroundColor: matchingEvent.banner_background_color,
        color: matchingEvent.banner_text_color,
        padding: '0.28125rem 0.625rem',
        textAlign: 'center',
        borderBottom: '0.0625rem solid var(--betterplace-color-bg-light, white)',
        borderRadius: '0.25rem 0.25rem 0 0',
      }}
    >
      {matchingEventBannerWording}
    </div>
  )
}
