import React, { useCallback } from 'react'

import { ModalLinkProps } from './types'
import { ModalWithTrigger } from '../modal_with_trigger'

// Usage example (in slim)
//
// div data-behavior='modal-link' data-content-id='#unique-modal-id' data-trigger='Open Modal' data-title='Modal Title'
//  optionally: data-css-class, data-clicktracking-name
//
// div style='display: none'
//   #unique-modal-id content for the modal

export const ModalLink = ({ contentId, ...props }: ModalLinkProps): JSX.Element => {
  const ref = useCallback(
    (ref: HTMLDivElement | null): void => {
      if (!ref) return
      const content = document.querySelector(contentId)
      if (!content) return
      ref.appendChild(content.cloneNode(true))
    },
    [contentId]
  )
  return (
    <ModalWithTrigger {...props}>
      <div ref={ref} />
    </ModalWithTrigger>
  )
}
