import { HotjarProps } from './trackers'
import { useEffect } from 'react'
/**
 * @todo Re-factor into a hook
 */
export const Hotjar = (props: HotjarProps): null => {
  useEffect(() => {
    if (props.hotjarId) {
      // TODO: Check if this re-factoring does not affect the tracking
      window.hj =
        window.hj || /** @type {typeof window.hj} */ ((...args) => (window.hj.q = window.hj.q || []).push(args))
      window._hjSettings = { hjid: props.hotjarId, hjsv: 6 }
      const head = document.getElementsByTagName('head')[0]
      const hotjarScript = document.createElement('script')
      hotjarScript.async = true
      hotjarScript.src = `https://static.hotjar.com/c/hotjar-${window._hjSettings.hjid}.js?sv=${window._hjSettings.hjsv}`
      head?.appendChild(hotjarScript)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // TODO: Determine whether it should depend on ids

  return null
}
