import autosize from 'autosize'
import { useEffect } from 'react'

export const useTextareaAutosize = (textarea: HTMLTextAreaElement | null) => {
  useEffect(() => {
    if (textarea) {
      autosize(textarea)
    }
  }, [textarea])
}
