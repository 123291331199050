import React from 'react'
import { A11y, Mousewheel, Pagination } from 'swiper/modules'
import { Swiper as SwiperJs, SwiperSlide as SwiperJsSlide } from 'swiper/react'

export const Swiper = ({ slides: slides_, pagination }: { slides: string; pagination: string }) => {
  const slides: string[] = JSON.parse(slides_)

  return (
    <div className="relative">
      {pagination === 'arrow-buttons'}
      <div className="generic-teaser-swiper select-none">
        <SwiperJs
          modules={[Pagination, Mousewheel, A11y]}
          className="pb-0"
          slidesPerView={1}
          pagination={slides.length > 1 ? { clickable: true } : false}
          roundLengths
          mousewheel={{ forceToAxis: true }}
          breakpoints={{
            451: {
              slidesPerView: 3,
            },
          }}
        >
          {slides.map((id) => (
            <SwiperJsSlide key={id}>
              <div
                ref={(ref) => {
                  const slide = document.getElementById(id)?.cloneNode(true)
                  ref && slide && ref.appendChild(slide)
                }}
              />
            </SwiperJsSlide>
          ))}
        </SwiperJs>
      </div>
    </div>
  )
}
