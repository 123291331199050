import React, { useMemo } from 'react'
import classNames from 'classnames'
import styles from './status_message_box.module.css'
import { StatusMessageBoxProps } from './types'

export const StatusMessageBox = ({ variant, dashed, centered, children }: StatusMessageBoxProps) => {
  const classes = useMemo(
    () =>
      classNames(styles.container, {
        [styles.variantSuccess]: variant === 'success',
        [styles.variantWarning]: variant === 'warning',
        [styles.variantInfo]: variant === 'info',
        [styles.dashed]: dashed,
        [styles.centered]: centered,
      }),
    [centered, dashed, variant]
  )

  return <div className={classes}>{children}</div>
}
