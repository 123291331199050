/* eslint-disable react/no-unknown-property */
import React from 'react'
import classNames from 'classnames'

import { themeColor } from 'shared'

export const NavigationArrow = ({
  direction,
  id,
  size,
  additionalClassName,
}: {
  direction?: 'left' | 'right'
  id?: string
  size?: string
  additionalClassName?: string
}) => {
  const scale = size === 'small' ? '0.15' : '1'
  const strokeWidth = size === 'small' ? '2' : '3'
  const width = size === 'small' ? '7.017' : '34.258'
  const height = size === 'small' ? '11.203' : '63.366'

  let transform = ''
  if (size === 'small') {
    transform =
      direction === 'left' ? `translate(-2 -3.8) scale(${scale})` : `translate(2 3.8) scale(${scale}) rotate(180)`
  } else {
    transform =
      direction === 'left'
        ? `translate(2.137 2.121) scale(${scale})`
        : `translate(-2.137 -2.121) scale(${scale}) rotate(180)`
  }

  return (
    <div id={id} className={classNames('generic-navigation-arrow', additionalClassName)}>
      <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
        <path
          transform-box="fill-box"
          transform-origin="center"
          transform={transform}
          d="M30,59.124,0,29.277,30,0"
          fill="none"
          stroke={themeColor('teal-700')}
          strokeLinecap="round"
          strokeWidth={strokeWidth}
          vectorEffect="non-scaling-stroke"
        />
      </svg>
    </div>
  )
}
