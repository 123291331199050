/**
 * Every Layout: The Icon
 * @source https://every-layout.dev/layouts/icon/
 * @module icon-l
 * @description
 * A custom element for inline icon insertion
 * @property {string} space=null The space between the text and the icon. If null, natural word spacing is preserved
 * @property {string} label=null Turns the element into an image in assistive technologies and adds an aria-label of the value
 */
export default class Icon extends HTMLElement {
  render: () => void

  constructor() {
    super()
    this.render = () => {
      if (this.label) {
        this.setAttribute('role', 'img')
        this.setAttribute('aria-label', this.label)
      }

      if (this.space !== null) {
        const variantId = `Icon-${this.space}`
        this.dataset.i = variantId
        if (document.getElementById(variantId)) return

        const styleEl = document.createElement('style')
        styleEl.id = variantId
        styleEl.innerHTML = `
          [data-i="${variantId}"] {
            display: inline-flex;
            align-items: baseline;
          }

          [data-i="${variantId}"] > svg {
            margin-inline-end: ${this.space};
          }`
          .replace(/\s\s+/g, ' ')
          .trim()
        document.head.appendChild(styleEl)
      }
    }
  }

  get space() {
    return this.getAttribute('space') || null
  }

  set space(val) {
    if (val) {
      this.setAttribute('space', val)
    } else {
      this.removeAttribute('space')
    }
  }

  get label() {
    return this.getAttribute('label') || null
  }

  set label(val) {
    if (val) {
      this.setAttribute('label', val)
    } else {
      this.removeAttribute('label')
    }
  }

  static get observedAttributes() {
    return ['space', 'label']
  }

  connectedCallback() {
    this.render()
  }

  attributeChangedCallback() {
    this.render()
  }
}

if ('customElements' in window && undefined === customElements.get('icon-l')) {
  customElements.define('icon-l', Icon)
}
