import React, { useCallback, useRef, useState } from 'react'
import classNames from 'classnames'
import styles from './search.module.css'
import { SearchProps } from './types'

export const Search = ({ query = '', queryChanged, placeholder, label }: SearchProps): JSX.Element => {
  const [value, setValue] = useState(decodeURIComponent(query || '').replace(/\+/g, ' '))
  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    (event) => {
      event.preventDefault()
      queryChanged(encodeURIComponent(value))
      // blur input on mobile so that the keyboard closes and the user can see results
      window.innerWidth < 769 && inputRef.current && inputRef.current.blur()
    },
    [queryChanged, value]
  )

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
    setValue(event.target.value)
  }, [])

  const eraseInput = useCallback(
    (_) => {
      setValue('')
      queryChanged('')
    },
    [queryChanged]
  )

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <form onSubmit={handleSubmit}>
          <label htmlFor="keyword-search" className="block">
            {label}
          </label>
          <div className="input-group">
            <input
              onChange={handleChange}
              type="text"
              className={classNames(styles.searchInput, 'search-input form-control')}
              placeholder={placeholder ? placeholder : i18n.t('discover.categories.index.search.placeholder_text')}
              value={value}
              ref={inputRef}
              id="keyword-search"
            />

            {/* The erase button has to be in the middle in order to keep the rounded corners of the input group. */}
            {value && (
              <button type="button" className={styles.eraseButton} onClick={eraseInput}>
                <i className="fa fa-times" />
                <span className="sr-only">{i18n.t('verbs.reset')}</span>
              </button>
            )}
            <span className="input-group-append">
              <button type="submit" className={classNames('btn btn-primary', styles.searchButton)}>
                <i className="fa fa-search" />
                <span className="sr-only">{i18n.t('verbs.search')}</span>
              </button>
            </span>
          </div>
        </form>
      </div>
    </div>
  )
}
