import React from 'react'

import { Field } from 'formik'
import { StarredFieldProps } from './types'

export const StarredField = (props: StarredFieldProps): JSX.Element => (
  <Field
    {...props}
    placeholder={(props.placeholder ?? '') + '*'}
    title={props.title || i18n.t('misc.required_field')}
  />
)
