import React, { useEffect, useState } from 'react'
import useTween from 'react-use/lib/useTween' // TODO: revert import change when this issue is resolved: https://github.com/streamich/react-use/issues/2074
import { CounterWidgetProps } from './types'
import { JSONFetcher, formatNumber } from 'shared'
import { formatCents } from '@betterplace/utils'
import { useInView } from 'react-intersection-observer'

/**
 * Markup Example:
 * <span data-behavior="counter-widget"
 *       data-count-to-url="/de/fundraising-events/counters/xmas_streams"
 *       data-count-from="3.142424242"
 *       data-count-duration="5"
 * />
 */

/**
 * @desc Provides animated counting from one number to another.
 */
export const CounterWidget = (props: CounterWidgetProps): JSX.Element => {
  const [ref, visible] = useInView({ fallbackInView: true, triggerOnce: true })

  return <span ref={ref}>{visible ? <Counter {...props} /> : ' '}</span>
}

function useCountTo(url: string, defaultCountTo: NullableNumLike): number {
  const [countTo, setCountTo] = useState(+(defaultCountTo ?? 0))
  useEffect(() => {
    if (url) {
      JSONFetcher.load<{ value: number }>({
        url,
        success: ({ value }) => {
          setCountTo(+value)
        },
      })
    }
  }, [url])
  return countTo
}

const Counter = ({
  countTo: countTo_,
  countFrom: countFrom_,
  countDuration: countDuration_,
  countEasing = 'inOutCubic',
  countDelay: countDelay_,
  countToUrl,
  formatPrecision,
  formatCurrency,
}: CounterWidgetProps): JSX.Element => {
  const countFrom = +(countFrom_ || 0)
  const countDuration = 1000 * +(countDuration_ || 3)
  const countDelay = 1000 * +(countDelay_ || 0)
  const countTo = useCountTo(countToUrl, countTo_)
  const tween = useTween(countEasing, countDuration, countDelay)
  return <>{format(countFrom + tween * (countTo - countFrom), formatPrecision, formatCurrency)}</>
}

const format = (number: number, precision_: NullableNumLike, symbol?: string): string => {
  const precision = +(precision_ ?? 0)
  if (symbol) {
    const options = {
      symbol,
      precision,
    }
    return formatCents(i18n.locale, number, options)
  }
  return formatNumber(number, { precision })
}
