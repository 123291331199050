import React, { useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'
import styles from './cta_bar.module.css'
import useWindowScroll from 'react-use/lib/useWindowScroll' // TODO: revert import change when this issue is resolved: https://github.com/streamich/react-use/issues/2074
import { CtaBarProps } from './types'

export const CtaBar = ({ children, hasStatusMessage, sticky }: CtaBarProps) => {
  const [visibleOnSmallScreens, setVisibleOnSmallScreens] = useState(true)

  const handleScroll = useCallback(() => {
    setVisibleOnSmallScreens(window.innerHeight + window.scrollY <= document.body.offsetHeight - 2)
  }, [])

  const { y } = useWindowScroll()
  useEffect(() => {
    y === 0 || handleScroll()
  }, [y, handleScroll])

  return (
    <div
      className={classNames(styles.container, {
        [styles.sticky]: sticky,
        [styles.removedFromLargeScreens]: sticky && hasStatusMessage,
        [styles.invisibleOnSmallScreens]: !visibleOnSmallScreens,
      })}
    >
      <div className={styles.childrenContainer}>{children}</div>
    </div>
  )
}
