/**
 * Every Layout: The Box
 * @source https://every-layout.dev/layouts/box/
 * @module box-l
 * @description
 * A custom element for generic boxes/containers
 * @property {string} padding=var(--space) A CSS `padding` value
 * @property {string} borderWidth=var(--border-thin) A CSS `border-width` value
 * @property {boolean} invert=false Whether to apply an inverted theme. Only recommended for greyscale designs.
 */
export default class Box extends HTMLElement {
  render: () => void

  constructor() {
    super()
    this.render = () => {
      const variantId = `Box-${[this.padding, this.borderWidth, this.invert].join('')}`
      this.dataset.i = variantId
      if (document.getElementById(variantId)) return

      const styleEl = document.createElement('style')
      styleEl.id = variantId
      styleEl.innerHTML = `
        [data-i="${variantId}"] {
          padding: ${this.padding};
          border: ${this.borderWidth} solid;
          ${
            this.invert
              ? `background-color: var(--color-light);
            filter: invert(100%);`
              : ''
          }
        }

        [data-i="${variantId}"] {
          background-color: inherit;
        }`
        .replace(/\s\s+/g, ' ')
        .trim()
      document.head.appendChild(styleEl)
    }
  }

  get padding() {
    return this.getAttribute('padding') || 'var(--space)'
  }

  set padding(val) {
    this.setAttribute('padding', val)
  }

  get borderWidth() {
    return this.getAttribute('borderWidth') || 'var(--border-thin)'
  }

  set borderWidth(val) {
    this.setAttribute('borderWidth', val)
  }

  static get observedAttributes() {
    return ['borderWidth', 'padding', 'invert']
  }

  get invert() {
    return this.hasAttribute('invert')
  }

  set invert(val) {
    if (val) {
      this.setAttribute('invert', '')
    } else {
      this.removeAttribute('invert')
    }
  }

  connectedCallback() {
    this.render()
  }

  attributeChangedCallback() {
    this.render()
  }
}

if ('customElements' in window && undefined === customElements.get('box-l')) {
  customElements.define('box-l', Box)
}
