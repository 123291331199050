import React from 'react'

export const LogoCompact = ({ color, style = {} }: { color?: string; style?: React.CSSProperties }) => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" style={style} x="0px" y="0px" viewBox="0 0 427.4 511">
      <path
        fill={color || '#9ECB09'}
        d="M304.6,413.9c13.8-14.8,29.4-31.8,50.6-54.6c71.9-77.8,85.2-159.2,61.3-226.4C393.9,69.5,309.6,0,214.7,0
      C117.2,0.7,32.1,66.3,10.5,133C-4,177.8-18,261.1,75.1,360.7L214.4,511l29.9-32.2c0,0-98-106.1-133.6-144.5
      c-28.8-35.4-78.6-98.7-58-181.9c7.9-31.7,64.5-108.8,162.1-108.5c97.3,0.3,150.5,80.8,159.3,104.5c20.4,55.1,9,117-55,185.5
      c-22.7,24.3-10.9,11.8-34,36.6c0,0-94.3-102.3-118.5-128.7c-17.9-19.6-20.8-51.3-1.1-70.6c19.3-19,49.3-24.6,73.4-1.6l28.3,30.9
      c14.7,15.3,20.8,15.1,34.3,1.1c10.5-10.8,34.9-37.7,41.1-44.6c-10.8-10.5-19.5-19-31.2-30.7c-6.9,7.5-22.8,24.6-26.6,28.6
      c-15.2-16.4-34.5-43.4-80.4-43.9c-28,0-52.7,11.9-69.8,28.8c-23.3,25.6-27.5,47.4-28,59.4c-0.9,24.7,1.9,45.2,32.7,78.7
      c50.3,54.5,111.9,120.6,126.8,136.6C280.9,429.9,290,429.5,304.6,413.9z"
      />
    </svg>
  )
}
