import 'whatwg-fetch'
import axios, { AxiosError } from 'axios'
import { CallOptions, LoadOptions, SubmitOptions, WithBodyOptions, WithoutBodyOptions } from './types'
import { debug } from '../debug'
import { handleError } from 'shared'

// Preferably use the `useJsonApi` webhook.
// Otherwise use `.call` for Promise/await based fetching.
// The other methods require a `success` callback.
export class JSONFetcher {
  static load<Out>({ url, success, signal, config }: LoadOptions<Out>) {
    axios
      .get(url, {
        signal,
        ...config,
      })
      .then((response) => success?.(response.data))
      .catch((error: AxiosError) => {
        handleError(error)
        debug(error)
      })
  }

  static post<In, Out>({ ...opts }: WithBodyOptions<In, Out>) {
    this.submit({ method: 'post', ...opts })
  }

  static put<In, Out>({ ...opts }: WithBodyOptions<In, Out>) {
    this.submit({ method: 'put', ...opts })
  }

  static delete({ ...opts }: WithoutBodyOptions<never>) {
    this.submit({ method: 'delete', ...opts })
  }

  static async call<In, Out>({ ...opts }: CallOptions<In, Out>): Promise<Out> {
    return new Promise((resolve, reject) => {
      this.submit({ ...opts, success: resolve, onError: reject })
    })
  }

  static submit<In, Out>({ url, body, success, method = 'get', onError, signal, config }: SubmitOptions<In, Out>) {
    const customConfig = {
      ...config,
      url,
      method,
      signal,
      data: body,
      headers: { ...JSONFetcher.buildHeaders(), ...config?.headers },
    }

    axios
      .request(customConfig)
      .then((response) => success?.(response.data))
      .catch((error: AxiosError) => {
        handleError(error)
        debug(error)
        onError?.(error)
      })
  }

  static buildHeaders() {
    const token = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') ?? ''
    return {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': token,
    }
  }
}
