import React, { useState } from 'react'
import { NoticeProps } from './types'

export const Notice = ({ message, type, report }: NoticeProps): JSX.Element => {
  if (!type) type = 'error'
  const [reportSent, setReportSent] = useState<boolean>(false)

  const initialContent = (
    <>
      {typeof message === 'string' ? (
        message
      ) : (
        <ol>
          {message.map((text) => (
            <li key={text}>{text}</li>
          ))}
        </ol>
      )}
      {report ? (
        <button
          type="button"
          className="gentle-link"
          onClick={() => {
            report()
            setReportSent(true)
          }}
        >
          {i18n.t('errors.report_button')}
        </button>
      ) : null}
    </>
  )

  return (
    <div className={`bpc-notice bpc-notice--${type}`}>
      <div className="bpc-notice-content">{reportSent ? i18n.t('errors.report_sent') : initialContent}</div>
    </div>
  )
}
