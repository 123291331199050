export const advanceDetailsDisclosures = () => {
  const isDesktop = () => window.innerWidth > 450

  // Let auto-toggle elements open and close on desktop and mobile screens, respectively.
  const autoDisclosures = document.querySelectorAll<HTMLDetailsElement>('details[data-auto-toggle]')
  if (autoDisclosures.length) {
    const toggleByViewport = (disclosures: NodeListOf<HTMLDetailsElement>) => {
      disclosures.forEach((d) => (d.open = isDesktop()))
    }
    window.addEventListener('resize', () => toggleByViewport(autoDisclosures))
    toggleByViewport(autoDisclosures)
  }

  // Keep mobile-only elements always open on desktop.
  const mobileDisclosures = document.querySelectorAll<HTMLDetailsElement>('details[data-only-mobile]')
  if (mobileDisclosures.length) {
    const openOnDesktop = (disclosure: HTMLDetailsElement): void => {
      if (isDesktop()) disclosure.open = true
    }
    const preventToggleOnDesktop = (disclosure: HTMLDetailsElement): void => {
      disclosure.querySelector('summary')?.addEventListener('click', (event) => {
        if (isDesktop()) event.preventDefault()
      })
    }

    window.addEventListener('resize', () => mobileDisclosures.forEach(openOnDesktop))
    mobileDisclosures.forEach((d) => (openOnDesktop(d), preventToggleOnDesktop(d)))
  }
}
