import { AxiosError } from 'axios'
import { Notice, renderWithLocale, report } from 'shared'
import { ReportableError } from '@betterplace/error-reporting'
import { createElement } from 'react'
import { scrollToTop } from '../scroll_helper'

const IGNORE_ERROR_CODES = [AxiosError.ECONNABORTED, AxiosError.ERR_NETWORK, AxiosError.ERR_CANCELED]

const getUserErrorMessage = (status?: number) => {
  // Overview of HTTP response status codes:
  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Status

  const statusCode = status || 0
  switch (statusCode) {
    case 401:
    case 403:
    case 404:
    case 422:
    case 503:
    case 504:
    case 522:
      return i18n.t(`errors.messages.status.${statusCode}`)
    default:
      if (statusCode >= 400 && statusCode < 500) {
        return i18n.t('errors.messages.status.4xx')
      }

      if (statusCode >= 500) {
        return i18n.t('errors.messages.status.5xx')
      }

      return i18n.t('errors.messages.default')
  }
}

const renderErrorMessage = (message: string, report?: () => void) => {
  const modalContainer = document.getElementById('js-modal-notice-container')
  const defaultContainer = document.getElementById('js-notice-container')
  const container = modalContainer || defaultContainer // prefer modal if there is one
  if (!container) return
  renderWithLocale(createElement(Notice, { message, type: 'error', report }), container)
  if (!modalContainer) scrollToTop()
}

const shouldErrorBeIgnored = (error: ReportableError) => {
  // errors that we don't want to log or show at all
  if (error instanceof AxiosError) {
    if ((error.code && IGNORE_ERROR_CODES.includes(error.code)) || (error.response && error.response.status < 400)) {
      return true
    }
  }
  return false
}

const shouldErrorBeReported = (error: ReportableError) => {
  // errors that we want to show to the user but don't need to log, because we can't do anything about them
  // for example client errors like "you are not authenticated"
  if (error instanceof AxiosError) {
    if (error.response && error.response.status >= 100 && error.response.status < 500) {
      return false
    }
  }
  return true
}

export const handleError = (error: ReportableError, userMessage?: string): void => {
  // we don't want the error to bubble further if any of the error handling steps failed
  // for instance I18n bundle seems to fail sometime to load on server start
  // or Honeybadger fails synchronously, bubbling could cause additional error notification spam
  try {
    if (shouldErrorBeIgnored(error)) return
    if (shouldErrorBeReported(error)) report(error)

    renderErrorMessage(
      userMessage || getUserErrorMessage(error instanceof AxiosError ? error.response?.status : undefined)
    )
  } catch (e) {
    // Here we die
    console.error(e)
  }
}

export const handleManuallyReportableError = (error: ReportableError, userMessage: string): void => {
  try {
    renderErrorMessage(userMessage, () => report(error))
  } catch (e) {
    // Here we die
    console.error(e)
  }
}
