/**
 * Every Layout: The Cluster
 * @source https://every-layout.dev/layouts/cluster/
 * @module cluster-l
 * @description
 * A custom element for grouping items, with control over the margin between them
 * @property {string} justify=flex-start A CSS `justify-content` value
 * @property {string} align=flex-start A CSS `align-items` value
 * @property {string} space=var(--space) A CSS `gap` value. The minimum space between the clustered child elements.
 */
export default class Cluster extends HTMLElement {
  render: () => void

  constructor() {
    super()
    this.render = () => {
      const variantId = `Cluster-${[this.justify, this.align, this.space].join('')}`
      this.dataset.i = variantId
      if (document.getElementById(variantId)) return

      const styleEl = document.createElement('style')
      styleEl.id = variantId
      styleEl.innerHTML = `
        [data-i="${variantId}"] {
          justify-content: ${this.justify};
          align-items: ${this.align};
          gap: ${this.space};
        }`
        .replace(/\s\s+/g, ' ')
        .trim()
      document.head.appendChild(styleEl)
    }
  }

  get justify() {
    return this.getAttribute('justify') || 'flex-start'
  }

  set justify(val) {
    this.setAttribute('justify', val)
  }

  get align() {
    return this.getAttribute('align') || 'flex-start'
  }

  set align(val) {
    this.setAttribute('align', val)
  }

  get space() {
    return this.getAttribute('space') || 'var(--space)'
  }

  set space(val) {
    this.setAttribute('space', val)
  }

  static get observedAttributes() {
    return ['justify', 'align', 'space']
  }

  connectedCallback() {
    this.render()
  }

  attributeChangedCallback() {
    this.render()
  }
}

if ('customElements' in window && undefined === customElements.get('cluster-l')) {
  customElements.define('cluster-l', Cluster)
}
