/**
 * Every Layout: The Stack
 * @source https://every-layout.dev/layouts/stack/
 * @module stack-l
 * @description
 * A custom element for injecting white space (margin) between flow
 * (block) elements along a vertical axis.
 * @property {string} space=var(--space) A CSS `margin` value
 * @property {number} splitAfter=null The element after which to _split_ the stack with an auto margin
 */
export default class Stack extends HTMLElement {
  render: () => void

  constructor() {
    super()
    this.render = () => {
      const variantId = `Stack-${[this.space, this.splitAfter].join('')}`
      this.dataset.i = variantId
      if (document.getElementById(variantId)) return

      const styleEl = document.createElement('style')
      styleEl.id = variantId
      styleEl.innerHTML = `
        [data-i="${variantId}"] {
          gap: ${this.space};
        }

        ${
          this.splitAfter
            ? `
              [data-i="${variantId}"]:only-child {
                block-size: 100%;
              }

              [data-i="${variantId}"] > :nth-child(${this.splitAfter}) {
                margin-block-end: auto;
              }`
            : ''
        }`
        .replace(/\s\s+/g, ' ')
        .trim()
      document.head.appendChild(styleEl)
    }
  }

  get space() {
    return this.getAttribute('space') || 'var(--space)'
  }

  set space(val) {
    this.setAttribute('space', val)
  }

  get splitAfter() {
    return this.getAttribute('splitAfter') || null
  }

  set splitAfter(val) {
    if (val) {
      this.setAttribute('splitAfter', val)
    } else {
      this.removeAttribute('splitAfter')
    }
  }

  static get observedAttributes() {
    return ['space', 'splitAfter']
  }

  connectedCallback() {
    this.render()
  }

  attributeChangedCallback() {
    this.render()
  }
}

if ('customElements' in window && undefined === customElements.get('stack-l')) {
  customElements.define('stack-l', Stack)
}
