import '@dnd-kit/accessibility'
import React from 'react'
import { CSS } from '@dnd-kit/utilities'
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { JSONFetcher } from 'shared/helpers/json_fetcher'
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers'

import { BaseItem, SortableItemProps, SortableProps } from './types'

import classnames from 'classnames'
import styles from '../sortable/sortable.module.css'
export const Sortable = <T extends BaseItem>({ items, setItems, ItemComponent }: SortableProps<T>) => {
  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 5 } }),
    useSensor(TouchSensor, { activationConstraint: { distance: 5 } }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  // be aware that data might come unsanitized. Do not use unsafe methods like dangerouslySetInnerHTML!
  return (
    <DndContext
      sensors={sensors}
      accessibility={{
        screenReaderInstructions: { draggable: i18n.t('shared.components.sortable.screen_reader_instructions') },
      }}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        <ol className="list-unstyled">
          {items.map((item: T) => (
            <SortableItem key={item.id} item={item} ItemComponent={ItemComponent} onDelete={setItems} />
          ))}
        </ol>
      </SortableContext>
    </DndContext>
  )

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event

    if (over && active.id !== over.id) {
      setItems((items: T[]) => {
        const oldIndex = items.findIndex((item) => item.id == active.id)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const url = items[oldIndex]!.moveUrl
        const newIndex = items.findIndex((item) => item.id == over.id)

        JSONFetcher.post({
          url,
          body: { position: newIndex },
          onError: (response) => {
            console.log('sorting failed', response)
          },
        })

        return arrayMove(items, oldIndex, newIndex)
      })
    }
  }
}

const SortableItem = <T extends BaseItem>({ item, ItemComponent, onDelete }: SortableItemProps<T>) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: item.id,
  })
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  }
  return (
    <li
      key={item.id}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className={styles.item}
      aria-roledescription={undefined} // dnd-kit sets it to button by default
      role={undefined} // dnd-kit sets it to draggable by default
    >
      <ItemComponent {...item} onDelete={onDelete} />
      <i className={classnames('fa fa-grip-vertical', styles.gripHandle)} />
    </li>
  )
}
