import MissingTranslationError from './missing_translation_error'
import { MissingTranslationStrategy } from 'i18n-js/typings'
import { isArray } from '@betterplace/utils'
import { report } from 'shared'
const Message: Record<string, string> = {
  de: 'Fehlende Übersetzung',
  en: 'Missing translation',
}

const missingTranslationStrategy: MissingTranslationStrategy = (i18n, scope) => {
  const locale = i18n.locale
  const key = isArray(scope) ? scope.join('.') : scope
  const trans = Message[locale]
  if (!trans) return `we don't handle ${locale}`
  const message = `${trans}: ${key}`
  report(new MissingTranslationError(message))
  return message
}

export default missingTranslationStrategy
