import tailwindConfig from '../../tailwind.config.js'

/**
 * @desc Usage `themeColor('green-500')` returns '#9ECB0A'
 */
export const themeColor = (colorName: keyof typeof colors) => {
  return colors[colorName]
}

/**
 *  @note Returns '541'
 */
export const themeBreakpoint = () => {
  return parseInt(screens['desktop'])
}

// Note: for more complex use cases, resolveConfig can be used,
// but not at runtime (extremely large and slow).
// For the colors, we can fortunately just peek into our theme directly.
// https://tailwindcss.com/docs/configuration#referencing-in-java-script
const { colors, screens } = tailwindConfig.theme
