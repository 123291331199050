export const RandomProjectHotkey = () =>
  window.addEventListener('keydown', (event) => {
    if (event.ctrlKey && event.altKey && (event.keyCode === 69 || event.keyCode === 79)) {
      // ctrl+alt+e or ctrl+alt+o
      event.preventDefault()
      document.body.innerHTML = document.body.innerHTML.replace(/ex/g, 'eggs')
      document.body.innerHTML = document.body.innerHTML.replace(/Ex/g, 'Eggs')
      document.body.innerHTML = document.body.innerHTML.replace(/ei/gi, '🥚')
      document.body.innerHTML = document.body.innerHTML.replace(/Spenden/g, 'Eier')
      document.body.innerHTML = document.body.innerHTML.replace(/Spende/g, 'Eigabe')
    } else if (event.ctrlKey && event.altKey && event.keyCode === 80) {
      // ctrl+alt+p
      event.preventDefault()
      window.location.href = '/projects/random'
    }
  })
