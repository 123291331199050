import React from 'react'

import { Button, LogoSpinner, ProjectPreviewList, themeColor } from 'shared'
import { RecordListLoadMoreButtonProps, RecordListLoadMoreButtonWrappedProps, RecordListProps } from './types'

/**
 * @func RecordList
 * @template T
 * @param {import('./types').RecordListProps<T>} props
 * @returns {JSX.Element}
 */
export const RecordList = <T,>({
  records,
  render,
  fancyButton = false,
  loading = false,
  loadMore,
  perPage,
  withoutNoResultMessage = false,
}: RecordListProps<T>): JSX.Element => {
  return (
    <div className="row">
      {records?.map((record, index) => (
        <div className="col-md-8 mb-4" key={index}>
          {render(record, index)}
        </div>
      ))}

      {records?.length > 0 ||
        (loading ? <ProjectPreviewList count={perPage} /> : <NoResult hidden={withoutNoResultMessage} />)}

      <LoadMoreButtonWrapped fancy={fancyButton} loading={loading} loadMore={loadMore} />
    </div>
  )
}

const LoadMoreButtonWrapped = (props: RecordListLoadMoreButtonWrappedProps): JSX.Element | null => {
  if (!props.loadMore) return null
  const label = i18n.t('discover.categories.index.moar')

  return (
    <div className="col-md-24 text-center">
      <LoadMoreButton {...props} label={label} />
    </div>
  )
}

const LoadMoreButton = ({ fancy, loading, loadMore, label }: RecordListLoadMoreButtonProps): JSX.Element => {
  if (fancy)
    return (
      <Button className="btn-large btn-primary" smallSpinner loading={loading} onClick={loadMore}>
        {label}
      </Button>
    )
  if (loading) return <LogoSpinner color={themeColor('green-500')} />
  return (
    <button className="simulated-link" onClick={loadMore}>
      {label}
    </button>
  )
}

const NoResult = ({ hidden }: { hidden: boolean }) => {
  if (hidden) return null
  return (
    <div className="generic-no-results text-center">
      {i18n.t('discover.categories.index.no_result_hint', { defaultValue: 'N/A' })}
    </div>
  )
}
