export const copyToClipboard = (value: string) => {
  const tempEl = document.createElement('textarea')
  tempEl.value = `${value}`
  document.body.appendChild(tempEl)
  tempEl.select()
  const success = document.execCommand('copy')
  document.body.removeChild(tempEl)

  if (!success) {
    alert('Sorry, dein Browser unterstützt diese Funktion nicht.')
  }

  return success
}
