import { useCallback, useEffect } from 'react'

export const useOnEscapePressed = (callback: () => void) => {
  const onEscapeClicked = useCallback(
    (evt: KeyboardEvent) => {
      if (evt.key !== 'Escape') return
      callback()
    },
    [callback]
  )
  useEffect(() => {
    document.addEventListener('keydown', onEscapeClicked)
    return () => {
      document.removeEventListener('keydown', onEscapeClicked)
    }
  }, [onEscapeClicked])
}
