import React, { useCallback } from 'react'
import analytics, { extractIdFromSlug } from '@betterplace/product-analytics'
import classNames from 'classnames'
import { DonateButtonProps } from './types'
import { StatusMessageBox } from 'ppp/status_message_box'

export const DonateButton = ({
  buttonLocation,
  size,
  block,
  status,
  donateUrl,
  progressPercentage,
  projectId,
  carrierBlocked,
}: DonateButtonProps): JSX.Element | null => {
  const trackDonationButtonClicked = useCallback(() => {
    analytics.donationButtonClicked({
      id: extractIdFromSlug(projectId),
      receiver_type: 'Project',
      button_location: buttonLocation,
    })
  }, [projectId, buttonLocation])

  if (status === 'preparing')
    return (
      <StatusMessageBox variant="info" dashed data-testid="ppp-donate-button">
        {i18n.t('projects.show.stats.donate_button_placeholder')}
      </StatusMessageBox>
    )

  if (status === 'blocked')
    return (
      <StatusMessageBox variant="warning" data-testid="ppp-donate-button">
        {i18n.t('projects.show.stats.donate_button_blocked_explanation')}
      </StatusMessageBox>
    )

  if (progressPercentage >= 100)
    return (
      <StatusMessageBox variant="success" centered data-testid="ppp-donate-button">
        {i18n.t('projects.show.stats.completed_label')}
      </StatusMessageBox>
    )

  if (status === 'closed')
    // (and not fully financed, see above)
    return (
      <StatusMessageBox variant="warning" data-testid="ppp-donate-button">
        {i18n.t('projects.show.stats.donate_button_closed_explanation')}
      </StatusMessageBox>
    )

  if (carrierBlocked)
    return (
      <StatusMessageBox variant="warning" data-testid="ppp-donate-button">
        {i18n.t('projects.show.stats.donate_button_blocked_explanation')}
      </StatusMessageBox>
    )

  const classes = classNames('btn', 'btn-primary', {
    'btn-block': block,
    'btn-large': size === 'large',
    'btn-huge': size === 'huge',
  })

  return (
    <a className={classes} data-testid="ppp-donate-button" href={donateUrl} onClick={trackDonationButtonClicked}>
      {i18n.t('projects.show.stats.donate_button_label')}
    </a>
  )
}
