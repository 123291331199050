import React, { useCallback, useState } from 'react'
import { I18nHtml, Modal } from 'shared'

type TaxNoticeProps = {
  className?: string
  highlightStart?: boolean
}

/**
 * @param className additional css class names for the element
 * @param highlightStart set to true if the first part of the sentence should be highlighted
 */
export const TaxNotice = ({ className, highlightStart }: TaxNoticeProps): JSX.Element => {
  const [showModal, setShowModal] = useState(false)
  const hideModal = useCallback(() => setShowModal(false), [setShowModal])
  const openModal = useCallback(() => setShowModal(true), [setShowModal])

  return (
    <>
      <p className={className}>
        {highlightStart ? (
          <strong>{i18n.t('shared.tax_deductibility_notice.start')}</strong>
        ) : (
          <span>{i18n.t('shared.tax_deductibility_notice.start')}</span>
        )}
        <span> {i18n.t('shared.tax_deductibility_notice.from')} </span>
        <button onClick={openModal} className="gentle-link">
          {i18n.t('shared.tax_deductibility_notice.button')}
        </button>
        <span>{i18n.t('shared.tax_deductibility_notice.recipient')}</span>
        <span>.</span>
      </p>
      <Modal
        id="tax-notice-modal"
        onHide={hideModal}
        show={showModal}
        header={i18n.t('shared.tax_deductibility_modal_headline')}
      >
        <p>
          <I18nHtml i18nKey="shared.tax_deductibility_modal_html" />
        </p>
        <div className="text-right">
          <button className="btn btn-primary" onClick={hideModal}>
            {i18n.t('misc.close')}
          </button>
        </div>
      </Modal>
    </>
  )
}
