import React, { useMemo, useRef } from 'react'
import { I18nHtmlProps } from './types'
import { TrustedHtml } from '../trusted_html'
import { decode } from 'html-entities'
import type { TranslateOptions } from 'i18n-js'

function unescapeInterpolationKeys(
  cache: Record<string, string>,
  options: TranslateOptions | undefined
): TranslateOptions | undefined {
  if (!options) return undefined
  return Object.fromEntries(
    Object.entries(options).map(([key, value]) => {
      const next = cache[`${key}_${value}`] || decode(value)
      cache[`${key}_${value}`] = next
      return [key, decode(value)] as const
    })
  )
}

export const I18nHtml = ({ i18nKey, options: options_, ...props }: I18nHtmlProps) => {
  const cache = useRef<Record<string, string>>({})
  const options = useMemo(() => unescapeInterpolationKeys(cache.current, options_), [options_])
  const children = useMemo(() => i18n.t(i18nKey, options), [i18nKey, options])
  return <TrustedHtml {...props}>{children}</TrustedHtml>
}
