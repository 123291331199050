import React, { useCallback, useEffect, useMemo, useState } from 'react'

export const PasswordReveal = ({ inputId }: { inputId: string }) => {
  const [type, setType] = useState('password')
  const input = useMemo(() => document.getElementById(inputId) as HTMLInputElement | null, [inputId])
  const toggleType = useCallback(() => setType((old) => (old === 'password' ? 'text' : 'password')), [setType])
  useEffect(() => {
    if (input) {
      input.type = type
    }
  }, [type, input])

  return (
    <button
      type="button"
      className={`password-reveal-icon fa fa-${type == 'text' ? 'eye-slash' : 'eye'}`}
      onClick={toggleType}
    />
  )
}
