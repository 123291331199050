import { Bounds } from 'discover/projects/bounds'
import { Query, QuerySorting } from './types'
import { QueryHelper } from 'shared'

export class ApiQuery {
  baseUrl = ''
  query: Query = {}
  filters: string[] = []
  orders: string[] = []

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl
  }

  addBounds(bounds: Bounds | undefined): ApiQuery {
    if (bounds) {
      Object.assign(this.query, {
        nelat: bounds.north,
        nelng: bounds.cappedEast,
        swlat: bounds.south,
        swlng: bounds.cappedWest,
      })
    }
    return this
  }

  page(value: number): ApiQuery {
    this.query.page = value
    return this
  }

  per(value: number): ApiQuery {
    this.query.per_page = value
    return this
  }

  order(value: QuerySorting): ApiQuery {
    this.orders = this.toKeyValueParam(value)
    return this
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filter<V extends Record<string, any>>(value: V): ApiQuery {
    this.filters = this.filters.concat(this.toKeyValueParam(value))
    return this
  }

  /**
   * @note This is pretty inconsistent. Why is `category_id` not a facet like everything else?
   */
  category(value: number | undefined | null = null) {
    this.query.category_id = value
    return this
  }

  withDefaultFilter(): ApiQuery {
    return this.filter({
      state: 'activated',
      min_activity_threshold_reached: true,
      hidden_from_platform: false,
    })
  }

  search(value?: string | undefined): ApiQuery {
    if (value) this.query.q = value
    return this
  }

  toUrl() {
    this.query.facets = this.toArrayParam(this.filters)
    this.query.order = this.toArrayParam(this.orders)
    return this.baseUrl + QueryHelper.toQuery(this.query)
  }

  toKeyValueParam<V extends {}>(obj: V) {
    return Object.entries(obj).map((part) => part.join(':'))
  }

  toArrayParam(arr: string[]) {
    return arr.join('|')
  }
}
