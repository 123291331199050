// As of 2022, the actual Desktop1x-url will point to a 618px wide image, but we live with 2px underdefinition

import React, { useCallback, useMemo, useRef, useState } from 'react'
import classNames from 'classnames'
import styles from './styles.module.css'
import { A11y, Keyboard, Mousewheel, Navigation, Pagination } from 'swiper/modules'
import { ApiQuery, findApiV4Link, useJsonApi, useMobile } from 'shared'
import { GalleryProps, PictureArray, PictureProps, PicturesApiResult } from './types'
import { NavigationArrow } from 'shared'
import { PPPProfilePicture } from 'ppp/types'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useOnEscapePressed } from 'shared/hooks/use_on_escape_pressed'

export const Gallery = ({ profilePicture, apiUrl }: GalleryProps): JSX.Element | null => {
  const query = new ApiQuery(apiUrl)
  //We display maximum 200 (this is the max amount per page)
  const currentPageUrl = query.per(200).toUrl()
  const { data, loading } = useJsonApi<PicturesApiResult>(currentPageUrl)
  const pictures = useMemo(
    () => [{ ...profilePicture, id: 0 }, ...(data?.data ?? [])] as PictureArray,
    [data, profilePicture]
  )
  const hasMultipleItems = pictures.length > 1

  if (!hasMultipleItems || loading)
    return (
      <div data-testid="gallery">
        <div className={styles.pictureContainer}>
          <ProfilePicture profilePicture={profilePicture} sizes="(max-width: 450px) 100vw, 620px" />
        </div>
        {hasMultipleItems && <div className={styles.galleryControl} />}
      </div>
    )

  return (
    <div data-testid="gallery">
      <Swiper
        modules={[A11y, Keyboard, Mousewheel, Navigation, Pagination]}
        className={styles.swiperContainer}
        slidesPerView={1}
        navigation={{
          nextEl: '#gallery-button-next',
          prevEl: '#gallery-button-prev',
          disabledClass: styles.isDisabled,
        }}
        keyboard
        centeredSlides
        roundLengths
        watchSlidesProgress={true}
        mousewheel={{ forceToAxis: true }}
        pagination={{
          clickable: true,
          dynamicBullets: true,
        }}
        a11y={{
          enabled: true,
          itemRoleDescriptionMessage: 'slide',
          containerRoleDescriptionMessage: 'slider',
        }}
        lazyPreloadPrevNext={3}
        onAfterInit={() => {
          Array.prototype.forEach.call(document.getElementsByClassName('swiper-pagination'), (element: HTMLElement) => {
            element.setAttribute('aria-hidden', 'true')
          })
          Array.prototype.forEach.call(
            document.getElementsByClassName('swiper-pagination-bullet'),
            (element: HTMLElement) => {
              element.setAttribute('tabindex', '-1')
            }
          )
        }}
        onSlideChange={() =>
          Array.prototype.forEach.call(
            document.getElementsByClassName('swiper-pagination-bullet'),
            (element: HTMLElement) => {
              element.setAttribute('tabindex', '-1')
            }
          )
        }
      >
        {pictures.map((picture, index) => {
          return (
            <SwiperSlide key={`pic-${picture.id}`} data-id={picture.id} className={styles.pictureContainer}>
              {({ isActive }) =>
                index ? (
                  <Picture
                    {...picture}
                    index={index}
                    total={data?.total_entries || undefined}
                    close={close}
                    isActiveSlide={isActive}
                  />
                ) : (
                  <ProfilePicture
                    profilePicture={picture as unknown as PPPProfilePicture}
                    sizes="(max-width: 450px) 95vw, 620px"
                  />
                )
              }
            </SwiperSlide>
          )
        })}
        <div className={styles.galleryControl}>
          <button className={classNames(styles.galleryButton, styles.galleryButtonPrev)} id="gallery-button-prev">
            <NavigationArrow direction="left" additionalClassName="ml-0" size="small" />
          </button>
          <button className={classNames(styles.galleryButton, styles.galleryButtonNext)} id="gallery-button-next">
            <NavigationArrow direction="right" additionalClassName="ml-0" size="small" />
          </button>
        </div>
      </Swiper>
    </div>
  )
}

const Picture = ({ close, index, total, isActiveSlide, ...picture }: PictureProps): JSX.Element => {
  const srcUrl1x = useMobile()
    ? findApiV4Link({ ofObject: picture, withRel: 'limit_450x235' })
    : findApiV4Link({ ofObject: picture, withRel: 'limit_620x323' })
  const srcUrl2x = useMobile()
    ? findApiV4Link({ ofObject: picture, withRel: 'limit_450x235_2x' })
    : findApiV4Link({ ofObject: picture, withRel: 'limit_620x323_2x' })
  const [showPictureInfo, setShowPictureInfo] = useState(false)
  const pictureRef = useRef<HTMLElement>(null)
  const openButtonRef = useRef<HTMLButtonElement>(null)
  const closeInfo = useCallback(() => {
    setShowPictureInfo(false)
    if (pictureRef.current?.contains(document.activeElement)) {
      openButtonRef.current?.focus()
    }
  }, [setShowPictureInfo])
  const openInfo = useCallback(() => setShowPictureInfo(true), [setShowPictureInfo])
  useOnEscapePressed(closeInfo)

  return (
    <figure ref={pictureRef}>
      <img loading="lazy" srcSet={`${srcUrl1x ?? ''}, ${srcUrl2x ?? ''} 2x`} className={styles.picture} alt="" />
      {picture.description && (
        <>
          <button
            className={styles.btnOverImage}
            tabIndex={!isActiveSlide || showPictureInfo ? -1 : undefined}
            onClick={openInfo}
            ref={openButtonRef}
          >
            <i className="fa fa-info-circle pr-1" style={{ color: 'white' }}></i>
            <span>{i18n.t('projects.show.gallery.info_trigger')}</span>
          </button>
          <figcaption className={classNames(styles.pictureInfo, showPictureInfo ? 'flex' : 'hidden')}>
            <span>{picture.description}</span>
            <button onClick={closeInfo} tabIndex={!isActiveSlide ? -1 : undefined}>
              <i className="fas fa-times" style={{ color: 'white' }}></i>
              <span className="sr-only">{i18n.t('verbs.close')}</span>
            </button>
          </figcaption>
        </>
      )}
    </figure>
  )
}

const ProfilePicture = ({ profilePicture, sizes }: { profilePicture: PPPProfilePicture; sizes: string }) => {
  return (
    <figure>
      <img
        src={profilePicture.desktop1xUrl}
        srcSet={`${profilePicture.mobile1xUrl} 450w, ${profilePicture.desktop1xUrl} 620w, ${profilePicture.mobile2xUrl} 900w, ${profilePicture.desktop2xUrl} 1240w`}
        sizes={sizes}
        alt=""
        className={styles.picture}
      />
    </figure>
  )
}
