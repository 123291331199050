import React from 'react'
import { SwiperNaginationProps } from './types'

export const SwiperNavigation = ({ prefix }: SwiperNaginationProps): JSX.Element => {
  return (
    <div className={'button-group'} data-testid="pagination">
      <button id={`${prefix}-prev`} className="btn btn-arrow">
        <i className="fa fa-caret-left" aria-hidden={true}></i>
        <span className="sr-only">{i18n.t('misc.previous')}</span>
      </button>
      <button id={`${prefix}-next`} className="btn btn-arrow">
        <i className="fa fa-caret-right" aria-hidden={true}></i>
        <span className="sr-only">{i18n.t('misc.next')}</span>
      </button>
    </div>
  )
}
