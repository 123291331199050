import React from 'react'
import { report } from 'shared'

type ErrorBoundaryProps<T extends {}> = {
  errorStateComponent?: React.ComponentType
  fallbackComponent?: React.ComponentType<React.PropsWithChildren<T>>
}
type ErrorBoundaryState = { hasError: boolean }

const DefaultErrorComponent = () => {
  return <p className="text-red-700">{i18n.t('errors.messages.default')}</p>
}

export class ErrorBoundary<T extends {}> extends React.Component<T & ErrorBoundaryProps<T>, ErrorBoundaryState> {
  state = { hasError: false }

  componentDidMount(): void {
    this.reset()
  }

  componentWillUnmount(): void {
    this.reset()
  }

  reset = () => {
    this.setState({ hasError: false })
  }

  /**
   * @param {any} _error
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static getDerivedStateFromError(_error: any) {
    return { hasError: true }
  }

  componentDidCatch(error: Error, info: unknown) {
    report(error, { info })
  }

  render() {
    if (this.state.hasError) {
      const { errorStateComponent, fallbackComponent, ...props } = this.props

      const ErrorStateComponent = this.props.errorStateComponent
        ? (this.props.errorStateComponent as React.ComponentType)
        : DefaultErrorComponent

      if (this.props.fallbackComponent) {
        const FallbackComponent = this.props.fallbackComponent as React.ComponentType<typeof props>

        return (
          <ErrorBoundary errorStateComponent={ErrorStateComponent}>
            <FallbackComponent {...props} />
          </ErrorBoundary>
        )
      }

      return <ErrorStateComponent />
    }

    return this.props.children
  }
}
