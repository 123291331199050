import React from 'react'
import classNames from 'classnames'

export const Table = ({ children, striped, small, bordered }: TableProps) => {
  return (
    <table
      className={classNames([
        'bpc-table',
        striped && 'bpc-table--striped',
        bordered && 'bpc-table--bordered',
        small && 'bpc-table--small',
      ])}
    >
      <tbody>{children}</tbody>
    </table>
  )
}
