import React from 'react'
import classNames from 'classnames'
import { FormGroupProps, GetErrorArguments } from './types'

export const FormGroup = ({
  children,
  field,
  form,
  hint,
  label,
  groupClass,
  labelClass,
  required,
  ...props
}: FormGroupProps): JSX.Element => {
  const error = fieldError({ field, form })
  const inputProps = { ...field, ...props } as HTMLProps<HTMLInputElement, typeof props & typeof field>
  return (
    <div className={classNames('form-group', { 'form-group-invalid': !!fieldError({ field, form }) }, groupClass)}>
      <label className={classNames('control-label', labelClass)} htmlFor={field.name}>
        {label} {required && <abbr title={i18n.t('misc.required_field')}>*</abbr>}
      </label>
      {children || <input id={field.name} className="form-control" type="text" {...inputProps} />}
      {error && <div className="help-inline">{error}</div>}
      {hint && <small className="form-text text-muted">{hint}</small>}
    </div>
  )
}

const fieldError = ({ field, form }: GetErrorArguments) => {
  if (form.touched[field.name]) return form.errors[field.name]
}
