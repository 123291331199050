import React, { useCallback, useEffect, useMemo, useState } from 'react'

import RBModal from 'react-bootstrap/Modal'
import { ModalProps } from './types'
type ModalState = 'exiting' | 'exited' | 'visible' | 'stuck' | 'hidden'
export const Modal = ({
  header,
  show,
  onHide,
  onShow,
  size = '',
  headerSubline,
  content,
  children,
  footer,
  id,
}: ModalProps): JSX.Element | null => {
  const [state, setState] = useState<ModalState>('hidden')
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let timeout: any
    if (show) {
      setState((state) => (state !== 'exiting' && state !== 'visible' ? 'visible' : state))
    } else {
      timeout = setTimeout(() => setState((state) => (state === 'exited' || state === 'stuck' ? 'hidden' : state)), 100)
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [show])

  const onExit = useCallback(() => {
    setState('exiting')
    const timeout = setTimeout(() => {
      setState((state) => (state === 'exiting' ? 'stuck' : state))
    }, 1000)
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [])

  const onExited = useCallback(() => {
    setState((state) => (state === 'exiting' ? 'exited' : state))
  }, [])

  const display = useMemo(() => ['exiting', 'visible'].includes(state), [state])
  if (!display) return null
  return (
    <RBModal
      show={show}
      onShow={onShow}
      onExit={onExit}
      onExited={onExited}
      onHide={onHide}
      dialogClassName={size}
      id={id}
      aria-labelledby={`modal-title-${id}`}
    >
      <RBModal.Header>
        <RBModal.Title id={`modal-title-${id}`}>
          <h2 className="m-0 text-2xl">{header}</h2>
          {headerSubline && <p className="mt-3 mb-3 text-lg font-normal">{headerSubline}</p>}
        </RBModal.Title>
        <button type="button" className="modal-close" aria-label={i18n.t('verbs.close')} onClick={onHide}></button>
      </RBModal.Header>

      <div className="generic-notice-container" role="alert">
        <div id="js-modal-notice-container"></div>
      </div>

      <RBModal.Body>
        {content}
        {children}
      </RBModal.Body>

      {footer && <RBModal.Footer>{footer}</RBModal.Footer>}
    </RBModal>
  )
}
