import React, { useCallback, useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
import styles from './color_input.module.css'
import useClickAway from 'react-use/lib/useClickAway'
import { ColorChangeHandler, ColorInputProps, PopoverPickerProps } from './types'
import { HexColorInput } from 'react-colorful'
import { themeColor, useModal } from 'shared'

export const ColorInput = ({
  onColorChange,
  transparentOption = false,
  value,
  label,
}: ColorInputProps): JSX.Element => {
  const presetColors = [
    themeColor('green-500'),
    themeColor('green-700'),
    themeColor('green-900'),
    themeColor('yellow-400'),
    themeColor('orange-500'),
    themeColor('red-700'),
    themeColor('red-900'),
    themeColor('orange-700'),
    themeColor('blue-400'),
    themeColor('blue-700'),
    themeColor('blue-900'),
    themeColor('purple-400'),
    themeColor('purple-700'),
    themeColor('gray-500'),
    themeColor('gray-700'),
    themeColor('gray-900'),
  ]

  if (transparentOption) {
    presetColors.push('#00000000')
  }

  return (
    <div className="input-group">
      <PopoverPicker
        color={`#${value === 'transparent' ? '00000000' : value}`}
        presetColors={presetColors}
        onChange={onColorChange}
        label={label}
      />
    </div>
  )
}
export const StandaloneColorInput = ({ control }: { control: HTMLInputElement }): JSX.Element => {
  const [value, setValue] = useState(control.value)
  const onColorChange: ColorChangeHandler = useCallback((value: string) => {
    setValue(value.replace('#', ''))
  }, [])
  useEffect(() => {
    control.value = value
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return <ColorInput onColorChange={onColorChange} value={value} />
}

const PopoverPicker = ({ color, presetColors, onChange, label }: PopoverPickerProps) => {
  const modalContent = useRef(null)
  const popoverTriggerRef = useRef<HTMLButtonElement>(null)
  const modal = useModal()

  const handleEnter: React.KeyboardEventHandler = useCallback(
    (event) => {
      if (event.key !== 'Enter') return
      event.preventDefault()
      modal.hide()
    },
    [modal]
  )

  const close = useCallback(() => {
    popoverTriggerRef.current?.focus()
    modal.hide()
  }, [modal])
  useClickAway(modalContent, close)

  return (
    <div className={styles.picker}>
      <button
        className={classnames(styles.swatch, styles.triggerSwatch)}
        style={{
          background: buildBackgroundStyles(color),
        }}
        onClick={(event) => {
          event.preventDefault()
          modal.toggle()
        }}
        ref={popoverTriggerRef}
      >
        {/* StandaloneColorInput does not provide a label */}
        <span className="sr-only">
          {label
            ? i18n.t('shared.color_input.sr_change_color_for', { label: label, color: color })
            : i18n.t('shared.color_input.sr_change_color', { color: color })}
        </span>
      </button>

      {modal.active && (
        <div className={styles.popover} ref={modalContent}>
          <div className={styles.pickerSwatches}>
            {presetColors.map((presetColor) => (
              <button
                key={presetColor}
                className={classnames(styles.swatch, styles.pickerSwatch, {
                  [styles.activePickerSwatch]: presetColor === color,
                })}
                style={{
                  background: buildBackgroundStyles(presetColor),
                }}
                onClick={(e) => {
                  e.preventDefault()
                  close()
                  onChange(presetColor)
                }}
              >
                <span className="sr-only">{presetColor}</span>
              </button>
            ))}
            <label htmlFor="hexColorInput" className="sr-only">
              {i18n.t('shared.color_input.sr_custom_color_label')}
            </label>
            <HexColorInput
              id="hexColorInput"
              color={color}
              onChange={onChange}
              alpha
              prefixed
              className={classnames('form-control', styles.input)}
              onKeyDown={handleEnter}
            />
          </div>
        </div>
      )}
    </div>
  )
}

const buildBackgroundStyles = (color: string) => {
  if (color.substring(1, 3) === '00' && color.length === 9) {
    return `url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAABlBMVEX////Z5exdL3Q7AAAAGklEQVQY02NgAAFGRkaa0gzo4gzo6hgGiTsANBAAYf1ZdsIAAAAASUVORK5CYII=')`
  } else {
    return color
  }
}
