import React, { useCallback } from 'react'
import { ControlsProps } from './types'

export const Controls = ({
  current,
  total,
  loadNextPage: loadNextPage_,
  loadPreviousPage: loadPreviousPage_,
}: ControlsProps): JSX.Element | null => {
  const loadPreviousPage = useCallback(() => {
    current > 1 && loadPreviousPage_()
  }, [current, loadPreviousPage_])

  const loadNextPage = useCallback(() => {
    current < total && loadNextPage_()
  }, [current, total, loadNextPage_])

  if (total <= 1) return null

  return (
    <div className="controls">
      <div className="button-group">
        <button className="btn btn-arrow" disabled={current === 1} onClick={loadPreviousPage}>
          <i className="fa fa-caret-left" aria-hidden={true}></i>
          <span className="sr-only">{i18n.t('misc.pagination.previous')}</span>
        </button>
        <button className="btn btn-arrow" disabled={current === total} onClick={loadNextPage}>
          <i className="fa fa-caret-right" aria-hidden={true}></i>
          <span className="sr-only">{i18n.t('misc.pagination.next')}</span>
        </button>
      </div>
    </div>
  )
}
