import React from 'react'
import classNames from 'classnames'
import styles from './cta_button_group.module.css'
import { CtaButtonGroupProps } from './types'
import { DonateButton } from 'ppp/donate_button'
import { SharingGridButton } from 'shared'

export const CtaButtonGroup = ({
  shareUrl,
  hasStatusMessage,
  hideDonateButton,
  utmMedium,
  utmContent,
  ...sharedDonateButtonProps
}: CtaButtonGroupProps) => {
  return (
    <div
      className={classNames(styles.container, { [styles.wrappedContainer]: hasStatusMessage })}
      data-testid="ppp-cta-button-group"
    >
      <SharingGridButton
        shareUrl={shareUrl}
        platforms={['email', 'facebook', 'twitter', 'link', 'whatsapp', 'messenger']}
        modalHeader={i18n.t('projects.show.stats.share_modal_headline')}
        buttonLabel={i18n.t('projects.show.stats.share_button_sticky_label')}
        size="large"
        utmMedium={utmMedium}
        utmContent={utmContent}
      />
      {!hideDonateButton && <DonateButton buttonLocation="PPP Sticky" size="large" {...sharedDonateButtonProps} />}
    </div>
  )
}
