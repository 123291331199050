import React from 'react'
import classNames from 'classnames'
import styles from './styles.module.css'
import { I18nHtml } from '../i18n_html'

export const OrganisationInfo = ({
  carrierProfilePictureUrl,
  carrierName,
  city,
  country,
  pictureWidth = 50,
  carrierUrl,
}: OrganisationInfoProps) => {
  return (
    <div className={classNames(styles.container, 'desktop:mb-0')}>
      <div className={styles.pictureContainer}>
        {carrierUrl ? (
          <a href={carrierUrl}>
            <Picture
              carrierProfilePictureUrl={carrierProfilePictureUrl}
              carrierName={carrierName}
              pictureWidth={pictureWidth}
            />
          </a>
        ) : (
          <Picture
            carrierProfilePictureUrl={carrierProfilePictureUrl}
            carrierName={carrierName}
            pictureWidth={pictureWidth}
          />
        )}
      </div>
      <div>
        <I18nHtml
          className={styles.name}
          i18nKey="projects.show.organisation_info.subline_carrier_html"
          options={{ carrier_name: carrierName }}
        />{' '}
        <I18nHtml
          className={styles.location}
          i18nKey={
            city || country
              ? 'projects.show.organisation_info.subline_location'
              : 'projects.show.organisation_info.location_fallback'
          }
          options={city || country ? { location: [city, country].filter(Boolean).join(', ') } : undefined}
        />
      </div>
    </div>
  )
}

const Picture = ({ carrierProfilePictureUrl, carrierName, pictureWidth }: PictureProps) => {
  return (
    <div className={classNames(styles.picture, 'mr-2')}>
      <img src={carrierProfilePictureUrl || ''} alt={carrierName || ''} style={{ width: `${pictureWidth}px` }} />
    </div>
  )
}
