/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */

import React, { useCallback, useState } from 'react'
import { I18nHtml, Modal, postMessageScrollTop } from 'shared'

type NewsletterLegalTermsProps = { textBeforeLink: string; linkText: string; textAfterLink: string }
export const NewsletterLegalTerms: React.FC<NewsletterLegalTermsProps> = ({
  textBeforeLink,
  linkText,
  textAfterLink,
}) => {
  const [showModal, setShowModal] = useState(false)
  const hideModal = useCallback(() => {
    setShowModal(false)
  }, [])
  const openModal = useCallback((event?: React.MouseEvent | React.KeyboardEvent) => {
    // Prevent the checkbox to (un)tick.
    if (event) event.preventDefault()

    setShowModal(true)
  }, [])
  const openModalOnKeyUp = useCallback(
    (event: React.KeyboardEvent) => {
      // Prevent the checkbox to (un)tick.
      if (event.key !== 'Enter') return

      openModal(event)
    },
    [openModal]
  )
  return (
    <>
      <div>
        {textBeforeLink}{' '}
        <span tabIndex={0} className="simulated-link larger-click-area" onClick={openModal} onKeyUp={openModalOnKeyUp}>
          {linkText}{' '}
        </span>
        {textAfterLink}
      </div>
      <Modal
        id="betterplace-newsletter-subscription-legal-modal"
        onHide={hideModal}
        onShow={postMessageScrollTop}
        show={showModal}
        header={i18n.t('shared.newsletter_consent.platform.legal_modal.heading')}
        footer={
          <button className="my-1 btn btn-primary" onClick={hideModal} type="button">
            {i18n.t('misc.close')}
          </button>
        }
      >
        <I18nHtml i18nKey="shared.newsletter_consent.platform.legal_modal.content" />
      </Modal>
    </>
  )
}
