/**
 * Every Layout: The Sidebar
 * @source https://every-layout.dev/layouts/sidebar/
 * @module sidebar-l
 * @description
 * A custom element for placing two elements side-by-side. If space permits, the sidebar element has a set width, and the companion takes up the rest of the available horizontal space. If not, the elements are collapsed into a single column, each taking up 100% of the horizontal space.
 * @property {string} side=start Which element to treat as the sidebar (all values but "start" are considered "end")
 * @property {string} sideWidth=null Represents the width of the sidebar _when_ adjacent. If not set (`null`) it defaults to the sidebar's content width
 * @property {string} contentMin=50% A CSS **percentage** value. The minimum width of the content element in the horizontal configuration
 * @property {string} space=var(--space) A CSS margin value representing the space between the two elements
 * @property {boolean} noStretch=false Make the adjacent elements adopt their natural height
 */
export default class Sidebar extends HTMLElement {
  render: () => void

  constructor() {
    super()
    this.render = () => {
      if (!this.contentMin.includes('%')) {
        console.warn(
          'The value for each <sidebar-l> `contentMin` property should be a percentage. Otherwise overflow is likely to occur'
        )
      }
      const variantId = `Sidebar-${[this.side, this.sideWidth, this.contentMin, this.space].join('')}`
      this.dataset.i = variantId
      if (document.getElementById(variantId)) return

      const styleEl = document.createElement('style')
      styleEl.id = variantId
      styleEl.innerHTML = `
        [data-i="${variantId}"] {
          gap: ${this.space};
          ${this.noStretch ? 'align-items: flex-start;' : ''}
        }

        [data-i="${variantId}"] > * {
          ${this.sideWidth ? `flex-basis: ${this.sideWidth};` : ''}
        }

        [data-i="${variantId}"] > ${this.side !== 'start' ? `:first-child` : `:last-child`} {
          flex-basis: 0;
          flex-grow: 999;
          min-inline-size: ${this.contentMin};
        }`
        .replace(/\s\s+/g, ' ')
        .trim()
      document.head.appendChild(styleEl)
    }
  }

  get side() {
    return this.getAttribute('side') || 'start'
  }

  set side(val) {
    this.setAttribute('side', val)
  }

  get sideWidth() {
    return this.getAttribute('sideWidth') || null
  }

  set sideWidth(val) {
    if (val) {
      this.setAttribute('sideWidth', val)
    } else {
      this.removeAttribute('sideWidth')
    }
  }

  get contentMin() {
    return this.getAttribute('contentMin') || '50%'
  }

  set contentMin(val) {
    this.setAttribute('contentMin', val)
  }

  get space() {
    return this.getAttribute('space') || 'var(--space)'
  }

  set space(val) {
    this.setAttribute('space', val)
  }

  get noStretch() {
    return this.hasAttribute('noStretch')
  }

  set noStretch(val) {
    if (val) {
      this.setAttribute('noStretch', '')
    } else {
      this.removeAttribute('noStretch')
    }
  }

  static get observedAttributes() {
    return ['side', 'sideWidth', 'contentMin', 'space', 'noStretch']
  }

  connectedCallback() {
    this.render()
  }

  attributeChangedCallback() {
    this.render()
  }
}

if ('customElements' in window && undefined === customElements.get('sidebar-l')) {
  customElements.define('sidebar-l', Sidebar)
}
