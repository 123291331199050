/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import shortAndSweet from 'short-and-sweet'
import { FormTextareaProps } from './types'
import { useTextareaAutosize } from 'shared/hooks/use_textarea_autosize'

function useShortAndSweet(fieldName: string): boolean {
  const [loadingComplete, setLoadingComplete] = useState(false)

  useEffect(() => {
    shortAndSweet(`#${fieldName}[maxlength]`, {
      counterClassName: 'module-text-counter',
      counterLabel: '{length} / {maxlength}',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      append: (el, counter) => {
        el.parentNode?.insertBefore(counter, el.nextSibling)
      },
    })
    setLoadingComplete(true) // force text area autosize to rerender
  }, [fieldName])
  return loadingComplete
}

export const FormTextArea = ({ field, form: { touched, errors }, hint, ...props }: FormTextareaProps): JSX.Element => {
  const hasError = touched[field.name] && errors[field.name]
  const classes = classNames(props.className, 'form-control', { 'field-with-error': hasError })
  const loadingComplete = useShortAndSweet(field.name)
  const textarea = useRef<HTMLTextAreaElement>(null)
  useTextareaAutosize(textarea.current)

  return (
    <div className={classNames('form-group', props.groupClass)}>
      <label className="control-label" htmlFor={field.name}>
        {props.label}
      </label>
      <textarea
        id={field.name}
        className={classes}
        {...(field as any)}
        {...(props as any)}
        data-loading-complete={loadingComplete.toString()}
        ref={textarea}
      />
      {hasError && <div className="help-inline">{errors[field.name]}</div>}
      {hint && <small className="form-text text-muted">{hint}</small>}
    </div>
  )
}
