import React, { useMemo } from 'react'
import { PlainTextProps } from './types'
import { decode } from 'html-entities'

/**
 * Use this component for inserting Text with HTML Entities.
 */
export const PlainText = ({ children, as = 'span', ...props }: PlainTextProps): JSX.Element => {
  const Tag = as
  const unescaped = useMemo(() => (children && typeof children === 'string' ? decode(children) : children), [children])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return <Tag {...(props as any)}>{unescaped}</Tag>
}
