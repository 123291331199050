import React from 'react'
import classNames from 'classnames'
import { HeroProps } from './types'

export const Hero = ({ className, imageUrl, title }: HeroProps) => {
  return (
    <div className={classNames(`generic-hero`, className)} style={{ backgroundImage: `url(${imageUrl})` }}>
      <h1 className="desktop:text-6xl">{title}</h1>
    </div>
  )
}
