import React from 'react'
import classNames from 'classnames'
import placeholder from './placeholder.svg'
import styles from './styles.module.css'
import { Blog, NewsTeaserProps } from './types'
import { I18nHtml } from 'shared'
import { isArray } from '@betterplace/utils'
import { truncate } from 'shared/helpers/text_helpers'

export const NewsTeaser = ({ news }: NewsTeaserProps): JSX.Element => {
  const imageUrlPattern =
    / src="(https:\/\/(betterplace-assets.betterplace.org|www.betterplace.dev|staging-assets.bp42.com)\/uploads[^"?]+\.(jpg|jpeg|gif|png))"/
  const match = news.body.match(imageUrlPattern)

  const getHrefByType = (news: Blog, linkType: 'self' | 'platform' | 'documentation') => {
    if (!news.links || !isArray(news.links)) return
    const links = news.links
    const link = links.find((link) => link.rel === linkType)

    return link?.href
  }

  //We have to make sure the different data structures work
  //There is a ticket to clean this up: https://betterplace.atlassian.net/browse/DEV-209
  const href = 'links' in news ? getHrefByType(news, 'platform') : news.href
  const ownerTitle = 'owner_title' in news && news.owner_title
  const ownerType = 'owner_type' in news && news.owner_type
  const imageUrl = 'image' in news ? news.image : match && match[1]

  return (
    <a href={href} className={styles.container}>
      {imageUrl ? (
        <div
          className={styles.imageContainer}
          style={{
            backgroundImage: 'url(' + imageUrl + ')',
          }}
          data-testid="news-teaser-image"
        ></div>
      ) : (
        <div
          className={classNames(styles.imageContainer, styles.placeholderImageContainer)}
          data-testid="news-teaser-image"
        >
          <img src={placeholder} alt="" className={styles.placeholderImage} />
        </div>
      )}

      <div className={styles.contentContainer}>
        <div className={styles.date} data-testid="news-teaser-date">
          {i18n.l('date.formats.default2', news.published_at)}
        </div>
        {/* Everything inside has to be inline in order to work in Safari. */}
        <div className={styles.truncateBox}>
          <h3 className={classNames(styles.title, ' mt-0')}>
            {news.title}
            <br />
          </h3>
          {/* Hack to add some white space between inline elements. */}
          <span style={{ fontSize: '2rem' }} />
          <span className={styles.preview}>
            {truncate(
              news.body
                .replace(/<(.|\n)*?>/g, '') // Remove HTML tags
                .replace(/\s\s+/g, ' '), // Remove multiple white spaces
              { length: 150 }
            )}
          </span>
        </div>
      </div>
      {ownerTitle && (
        <div className={styles.ownerInfoContainer}>
          <div className={styles.ownerInfoBox}>
            <I18nHtml
              as="span"
              i18nKey={
                ownerType === 'Project'
                  ? 'shared.news_teaser.project_info_html'
                  : 'shared.news_teaser.fundraising_event_info_html'
              }
              options={{ title: ownerTitle }}
            />
          </div>
        </div>
      )}
    </a>
  )
}
