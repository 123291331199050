/**
 * Every Layout: The Center
 * @source https://every-layout.dev/layouts/center/
 * @module center-l
 * @description
 * A custom element for centering a block-level element horizontally,
 * with a max-width value representing the typographic measure
 * @property {string} max=var(--container-size) A CSS `max-width` value
 * @property {boolean} andText=false Center align the text too (`text-align: center`)
 * @property {string} gutters=null The minimum space on either side of the content, defaults to 0
 * @property {boolean} intrinsic=false Center child elements based on their content width
 */
export default class Center extends HTMLElement {
  render: () => void

  constructor() {
    super()
    this.render = () => {
      const variantId = `Center-${[this.max, this.andText, this.gutters, this.intrinsic].join('')}`
      this.dataset.i = variantId
      if (document.getElementById(variantId)) return

      const styleEl = document.createElement('style')
      styleEl.id = variantId
      styleEl.innerHTML = `
        [data-i="${variantId}"] {
          max-inline-size: ${this.max};
          ${
            this.gutters
              ? `
                padding-inline-start: ${this.gutters};
                padding-inline-end: ${this.gutters};`
              : ''
          }
          ${this.andText ? `text-align: center;` : ''}
          ${
            this.intrinsic
              ? `
                display: flex;
                flex-direction: column;
                align-items: center;`
              : ''
          }
        }`
        .replace(/\s\s+/g, ' ')
        .trim()
      document.head.appendChild(styleEl)
    }
  }

  get max() {
    return this.getAttribute('max') || 'var(--container-size)'
  }

  set max(val) {
    this.setAttribute('max', val)
  }

  get andText() {
    return this.hasAttribute('andText')
  }

  set andText(val) {
    if (val) {
      this.setAttribute('andText', '')
    } else {
      this.removeAttribute('andText')
    }
  }

  get gutters() {
    return this.getAttribute('gutters') || null
  }

  set gutters(val) {
    if (val) {
      this.setAttribute('gutters', val)
    } else {
      this.removeAttribute('gutters')
    }
  }

  get intrinsic() {
    return this.hasAttribute('intrinsic')
  }

  set intrinsic(val) {
    if (val) {
      this.setAttribute('intrinsic', '')
    } else {
      this.removeAttribute('intrinsic')
    }
  }

  static get observedAttributes() {
    return ['max', 'andText', 'gutters', 'intrinsic']
  }

  connectedCallback() {
    this.render()
  }

  attributeChangedCallback() {
    this.render()
  }
}

if ('customElements' in window && undefined === customElements.get('center-l')) {
  customElements.define('center-l', Center)
}
