/**
 * Every Layout: The Switcher
 * @source https://every-layout.dev/layouts/switcher/
 * @module switcher-l
 * @description Switch directly between horizontal and vertical layouts at a given (container width-based) breakpoint or 'threshold'
 * @property {string} threshold=var(--threshold) A CSS `width` value (representing the 'container breakpoint')
 * @property {string} space=var(--space) A CSS `margin` value
 * @property {integer} limit=4 A number representing the maximum number of items permitted for a horizontal layout
 */
export default class Switcher extends HTMLElement {
  render: () => void

  constructor() {
    super()
    this.render = () => {
      const variantId = `Switcher-${[this.threshold, this.space, this.limit].join('')}`
      this.dataset.i = variantId
      if (document.getElementById(variantId)) return

      const styleEl = document.createElement('style')
      styleEl.id = variantId
      styleEl.innerHTML = `
        [data-i="${variantId}"] {
          gap: ${this.space};
        }

        [data-i="${variantId}"] > * {
          flex-basis: calc((${this.threshold} - 100%) * 999);
        }

        [data-i="${variantId}"] > :nth-last-child(n+${parseInt(this.limit) + 1}),
        [data-i="${variantId}"] > :nth-last-child(n+${parseInt(this.limit) + 1}) ~ * {
          flex-basis: 100%;
        }`
        .replace(/\s\s+/g, ' ')
        .trim()
      document.head.appendChild(styleEl)
    }
  }

  get threshold() {
    return this.getAttribute('threshold') || 'var(--threshold)'
  }

  set threshold(val) {
    this.setAttribute('threshold', val)
  }

  get space() {
    return this.getAttribute('space') || 'var(--space)'
  }

  set space(val) {
    this.setAttribute('space', val)
  }

  get limit() {
    return this.getAttribute('limit') || '5'
  }

  set limit(val) {
    this.setAttribute('limit', val)
  }

  static get observedAttributes() {
    return ['threshold', 'space', 'limit']
  }

  connectedCallback() {
    this.render()
  }

  attributeChangedCallback() {
    this.render()
  }
}

if ('customElements' in window && undefined === customElements.get('switcher-l')) {
  customElements.define('switcher-l', Switcher)
}
