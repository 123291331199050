import React, { useMemo } from 'react'
import sanitize from 'sanitize-html'
import { type Preset, SanitizationPresets, TrustedHtmlProps } from './types'
import { cloneDeep, merge } from 'lodash'

// Allow rel for an anchor tag in the default preset
sanitize.defaults?.allowedAttributes['a']?.push('href', 'target', 'rel')

const ASSET_URL = process.env.WEBPACKER_ASSET_HOST ?? 'https://www.betterplace.dev'
const withClassesAndStyles = merge(cloneDeep(sanitize.defaults), {
  allowedAttributes: {
    p: ['class', 'style'],
    div: ['class', 'style'],
    a: ['class', 'style', 'href', 'target', 'rel'],
    span: ['class', 'style'],
  },
  allowedStyles: {
    '*': {
      'color': [/^#([0-9a-f]{3}){1,2}$/i],
      'background-color': [/^#([0-9a-f]{3}){1,2}$/i],
      'text-align': [/^left$/, /^right$/, /^center$/, /^justify$/],
      'font-size': [/^\d+(?:px|em|%)$/],
      'font-weight': [/^.*$/],
    },
  },
} satisfies Preset)

const withEmbeddedMedia = merge(cloneDeep(withClassesAndStyles), {
  allowedTags: ['img', 'iframe'],
  allowedAttributes: {
    iframe: ['src', 'width', 'height', 'title'],
  },
  allowProtocolRelative: true,
  allowedIframeHostnames: ['www.youtube.com', 'player.vimeo.com'],
  exclusiveFilter: (frame) => {
    if (frame.tag !== 'img') return false
    if (!frame.attribs['src']?.startsWith(ASSET_URL)) return true
    return false
  },
} satisfies Preset)

const SanitizationPreset: Record<SanitizationPresets, Preset> = {
  [SanitizationPresets.MissingPreset]: false,
  [SanitizationPresets.Strict]: undefined,
  [SanitizationPresets.AllowClassesAndLinks]: withClassesAndStyles,
  [SanitizationPresets.RichTextWithMedia]: withEmbeddedMedia,
}

export function sanitizeWithPreset(value: string, preset: SanitizationPresets) {
  if (!value) return value
  const presetValue = SanitizationPreset[preset]
  // TODO: We support a missing preset by bypassing sanitization, please remove once all instances are converted
  if (presetValue === false) return value
  // TODO: Remove workaround for relative protocols after the BE transitioned them to https
  const input = value.replace(/"\/\//gi, '"https://')
  const result = sanitize(input, presetValue)
  return result
}

/**
 * Use this component for inserting HTML.
 */
export const TrustedHtml = ({
  children,
  as = 'span',
  sanitizationPreset = SanitizationPresets.Strict,
  ...props
}: TrustedHtmlProps): JSX.Element => {
  const Tag = as
  const __html = useMemo(() => sanitizeWithPreset(children, sanitizationPreset), [children, sanitizationPreset])
  return <Tag dangerouslySetInnerHTML={{ __html }} {...props} />
}
