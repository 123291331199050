import React, { useState } from 'react'
import useInterval from 'react-use/lib/useInterval' // TODO: revert import change when this issue is resolved: https://github.com/streamich/react-use/issues/2074
import { TimerComponentProps, TimerWidgetProps } from './types'

const propIsTrue = (prop: boolean | string | undefined): boolean => ![undefined, false, 'false', 'no'].includes(prop)

/**
 * Markup Example:
 * <span data-behavior="timer-widget"
 *       data-timer-key-date="Dec 1, 2019"
 *       data-timer-seconds-threshold="120"
 * ></span>
 */
/**
 * @desc Provides an animated timer to/from a given key date.
 */
export const TimerWidget = ({
  timerKeyDate,
  timerSecondsThreshold,
  timerHideZeros,
  timerStopOnKeyDate,
}: TimerWidgetProps): JSX.Element => {
  const keyDate = new Date(timerKeyDate)
  const secondsThreshold = timerSecondsThreshold ? +timerSecondsThreshold : Number.POSITIVE_INFINITY
  const hideZeros = propIsTrue(timerHideZeros)
  const stopOnKeyDate = propIsTrue(timerStopOnKeyDate)

  const getCurrentValue = () => Math.abs(keyDate.getTime() - new Date().getTime())

  const doNotDisplay = stopOnKeyDate && new Date() > keyDate

  const getCurrentInterval = () => {
    if (value / 1000 - 60 > secondsThreshold) {
      return value % 60000
    }
    if (doNotDisplay || (value < 500 && stopOnKeyDate)) {
      return null
    }
    return value % 1000
  }

  const [value, setValue] = useState(getCurrentValue())

  useInterval(() => {
    setValue(getCurrentValue())
  }, getCurrentInterval())

  if (doNotDisplay) return <></>

  return <TimerComponents totalMilliseconds={value} secondsThreshold={secondsThreshold} hideZeros={hideZeros} />
}

const TimerComponents = ({
  totalMilliseconds,
  secondsThreshold = Number.POSITIVE_INFINITY,
  hideZeros = false,
}: TimerComponentProps): JSX.Element => {
  const totalSeconds = Math.round(Math.abs(totalMilliseconds) / 1000)
  const timeStruct = {
    days: Math.floor(totalSeconds / 86400),
    hours: Math.floor(totalSeconds / 3600) % 24,
    minutes: Math.floor(totalSeconds / 60) % 60,
    seconds: totalSeconds < secondsThreshold ? totalSeconds % 60 : 0,
  }

  const timePieces = []
  for (const unit in timeStruct) {
    const u = unit as keyof typeof timeStruct
    if (timeStruct[u] || (!hideZeros && timePieces.length)) {
      timePieces.push(<TimerComponent key={unit} unit={unit} value={timeStruct[u]} />)
    }
  }

  return <>{timePieces}</>
}

const TimerComponent = ({ unit, value }: { unit: string; value: number }): JSX.Element => (
  <span className={`timer-component ${unit}`}>
    {value}
    <span className={'unit ' + (value === 1 ? 'singular' : 'plural')}>
      <span className="default">&nbsp;{unit.charAt(0)} </span>
    </span>
  </span>
)
